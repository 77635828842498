import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './plugins/store'
import { loadFonts } from './plugins/webfontloader'
import router from './router/index'
import './firebaseInit'
import axios from 'axios'
import { paymentStatus, replyType } from './constants'

loadFonts()

const app = createApp(App)

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$paymentStatus = paymentStatus;
app.config.globalProperties.$replyType = replyType;

app.use(vuetify)
   .use(router)
   .use(store)
   .mount('#app')