<template>
  <div>
    <v-toolbar app color="white" elevation="0">
     <v-img
        :src="require('@/assets/In-Auto3.svg')"
        height="55"
        contain
        style="max-width: 500px; max-height: 55px;"
      ></v-img>      
      <v-spacer></v-spacer>
      <!--
        <v-btn text >
          <span class="mr-2">機能</span>
        </v-btn>
        <v-btn text>
          <span class="mr-2">用途</span>
        </v-btn>
        <v-btn text >
          <span class="mr-2">デモ</span>
        </v-btn>
        <v-btn text >
          <span class="mr-2">価格</span>
        </v-btn>
        <v-btn text >
          <span class="mr-2">よくある質問</span>
        </v-btn>
        <v-btn color="white" text to="/signup">
          <span class="mr-2">ログイン</span>
        </v-btn>
      -->
      <v-btn to="/signup" color="primary">登録</v-btn>
      <v-btn to="/signin" color="primary">ログイン</v-btn>
    </v-toolbar>
  </div>
</template>

<style scoped>
.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}
</style>

<script>
export default {
  data: () => ({
    drawer: null,
    logoPath: require('@/assets/img/logo.png'),
  }),
  props: {
    color: String,
    flat: Boolean,
  },
  methods: {
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
  },
  mounted() {
  },
};
</script>
