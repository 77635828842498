export const paymentStatus = {
  initial: 0,
  trial:1,
  paid: 10,
  failure: 20,
  cancel: 99,
  leave: 100,
};

export const paymentItemType = {
  noItem: 0,
  trialStandard: 100,
  trialPro: 150,
  standard:200,
  pro: 300,
};

export const replyType = [
  { text: 'ストーリーズメンション', value: 0 },
  { text: '投稿コメント(リール、liveを含む)', value: 1 }
];