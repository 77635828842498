<template>
  <v-app>
    <v-main>
      <div v-if="$route.path === '/'">
        <WelcomePage/>
      </div>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import WelcomePage from './components/WelcomePage.vue'
import { auth } from "./firebaseInit.js";
import { mapGetters } from 'vuex';

export default {
  name: 'App',

  components: {
    WelcomePage,
  },

  data() {
    return {
      isUserLoggedIn: false,
    };
  },
  computed: {
  ...mapGetters(['currentUser'])
  },
  mounted() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.isUserLoggedIn = true;
        this.$store.commit("setUser", user);
        console.log(this.$store.state.user.email);
        console.log(this.currentUser.email);
      } else {
        this.isUserLoggedIn = false;
         console.log("a");
      }
    });
  }
}
</script>
