<template>
  <div class="floating-form">
    <v-card class="form-container" :class="{'fixed-dimensions': !isFolded}">
      <v-card-text v-if="!isFolded">
        <div class="base-container">
          <div v-for="component in components" :key="component.type" class="my-text-div">
            <div class="my-text-container">
              <template v-if="component.type === 'text'">
                <div class="my-text my-1" v-html="formatText(component.value)"></div>
              </template>
              <template v-else-if="component.type === 'image'">
                <div class="my-image-container my-1">
                  <v-img v-if="component.value !== null" :src="component.value" class="my-image"></v-img>
                  <template v-else>
                    <span>No Image Selected</span>
                  </template>
                </div>
              </template>
              <template v-else-if="component.type === 'button'">
                <v-btn>{{ component.buttonname }}</v-btn>
              </template>
              <template v-else-if="component.type === 'template'">
                <v-card class="mx-auto full-size">
                  <v-img
                    v-if="component.value !== null"
                    :src="component.value"
                    height="200"
                    cover
                    class="my-image align-end text-white"
                  ></v-img>
                  <v-card-title class="pt-4 mx-1"> {{component.title}} </v-card-title>
                  <v-card-subtitle class="pt-4 mx-1">{{component.subtitle}}</v-card-subtitle>
                  <v-card-actions class="justify-center">
                    <v-btn elevated> {{ component.buttonName }}</v-btn>
                  </v-card-actions>
                </v-card>
              </template>           
            </div>
          </div>
        </div>
      </v-card-text>
      <v-divider class="my-2"></v-divider>
      <span class="text-center">プレビュー</span>
      <v-icon class="fold-icon" @click="toggleFolded">{{ isFolded ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
    </v-card>
  </div>
</template>


<script>
export default {
  data() {
    return {
      isFormOpen: true,
      isFolded: true,
    };
  },
  methods: {
    toggleFolded() {
        this.isFolded = !this.isFolded;
    },
    formatText(value) {
      return value.replace(/\n/g, '<br />');
    },
  },
  props: {
    components: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Montserrat);
.floating-form {
  position: fixed;
  bottom: 20px;
  right: 20px;
  max-height: 50vh; 
  overflow-y: auto; 
}


.form-container {
  background: #f1f1f1;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  /*   outline: 1px solid white; */
}

body {
  background: #000000;
  height: 100vh;
  width: 100%;
  display: flex;
  padding-top: 1rem;
}
.base-container {
  width: 100%;
}
.friend-text-div {
  display: flex;
  margin-left: 0.5rem;
}

.friend-text-div > img {
  height: 2rem;
  align-self: flex-end;
}

.friend-text-container {
  width: 10rem;
  display: flex;
  flex-direction: column;
}

.friend-text {
  background: #262626;
  border-radius: 0.5rem;
  color: #fff;
  height: fit-content;
  width: fit-content;
  padding: 0.5rem 1rem;
  margin: 0.12rem 0.5rem;
}

.my-text-div {
  display: flex;
  justify-content: flex-end;
}
.my-text-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.my-text {
  background: linear-gradient(
      180deg,
      rgba(139, 47, 184, 1) 0%,
      rgba(103, 88, 205, 1) 51%,
      rgba(89, 116, 219, 1) 92%
    )
    no-repeat center;
  background-attachment: fixed;
  color: #fff;
  border-radius: 0.5rem 0.2rem 0.2rem 0.5rem;
  height: fit-content;
  width: fit-content;
  padding: 0.5rem 1rem;
  margin: 0.12rem 0.5rem;
}

.my-text-container > div:first-child {
  border-radius: 0.5rem 1rem 0.2rem 0.5rem;
}
.my-text-container > div:last-child {
  border-radius: 0.5rem 0.2rem 1rem 0.5rem;
}
.friend-text-container > div:first-child {
  border-radius: 1rem 0.5rem 0.2rem 0.5rem;
}
.friend-text-container > div:last-child {
  border-radius: 0.5rem 0.2rem 0.5rem 1rem;
}
.my-image-container {
  width: 200px;
  height: 200px;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fold-icon {
  cursor: pointer;
}

.fixed-dimensions {
  width: 300px; 
  overflow: auto;
}

.full-size {
  width: 100%;
  height: 100%;
}
</style>
