<template>
  <v-container>
    <v-row>
      <v-col cols="10">
        <v-table>
          <thead>
            <tr>
              <th class="text-left">
                名前
              </th>
              <th class="text-left">
                作成日
              </th>
              <th class="text-left">
                編集
              </th>
              <th class="text-left">
                削除
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="content in this.packages" :key="content.id">
                <td>{{ content.id }}</td>
                <td>{{  new Date(content.created_at.seconds * 1000).toLocaleDateString('ja-JP') }}</td>
                <td><v-icon small @click="editPackage(content)">mdi-pencil</v-icon></td>
                <td><v-icon small @click="deletePackage(content.id)">mdi-delete</v-icon></td>
            </tr>
          </tbody>
        </v-table>
      </v-col>
    </v-row>
      <v-dialog v-model="edit_dialog" max-width="700px">
        <div class="">
            <v-card style="overflow-y: auto; max-height: 500px;">
            <v-card-title>パッケージ作成</v-card-title>
            <v-card-text>
              <h3>パッケージの名前</h3>
              <v-text-field v-model="packageName" label="Package Name"></v-text-field>
            </v-card-text>
            <v-card-text>
                <v-form>
                    <template v-for="(component, index) in components" :key="index">
                    <v-card v-if="component.type === 'text'" padding="3" class="my-2">
                        <v-card-text>
                        <h3>テキスト</h3>
                        <v-textarea v-model="component.value" :label="'テキスト' + (index + 1)"></v-textarea>
                        <v-btn @click="removeField(index)">削除</v-btn>
                        </v-card-text>
                    </v-card>
                    <v-card v-if="component.type === 'image'" padding="3" class="my-2">
                        <v-card-text>
                        <h3>画像</h3>
                        <v-file-input
                            accept=".jpg, .png, .gif, .jpeg"
                            :label="getLabelWithImagePath(index, component.filename)"
                            @change="onFileChange($event, index)"
                        ></v-file-input>
                        <v-btn @click="removeField(index)">削除</v-btn>
                        </v-card-text>
                    </v-card>
                    <v-card v-if="component.type === 'button'" padding="3" class="my-2">
                        <v-card-text>
                        <h3>ボタン{{index + 1}}</h3>
                        <v-text-field v-model="component.buttonname" :label="'ボタン名'"></v-text-field>
                        <v-select v-model="component.buttontrigger" :items="['既定のURLに遷移']" :label="'トリガーの選択'"></v-select>
                        <v-text-field v-model="component.value" :label="'遷移先URL'"></v-text-field>
                        <v-btn @click="removeField(index)">削除</v-btn>
                        </v-card-text>
                    </v-card>
                     <v-card v-if="component.type === 'template'" padding="3" class="my-2">
                        <v-card-text>
                        <h3>テンプレート{{index + 1}}</h3>
                        <v-file-input
                            accept=".jpg, .png, .gif, .jpeg"
                            :label="getLabelWithImagePath(index, component.filename)"
                            :show-size="true"
                            @change="onFileChange($event, index)"
                        ></v-file-input>
                        <v-text-field v-model="component.title" :label="'タイトル'"></v-text-field>
                        <v-text-field v-model="component.subtitle" :label="'サブタイトル'"></v-text-field>
                        <v-text-field v-model="component.buttonName" :label="'ボタン名'"></v-text-field>
                        <v-text-field v-model="component.buttonValue" :label="'遷移先URL'"></v-text-field>
                        <v-btn @click="removeField(index)">削除</v-btn>
                        </v-card-text>
                    </v-card> 
                    </template>
                </v-form>
            </v-card-text>
            <v-row no-gutters class="justify-start mb-3">
              <v-col cols="4" sm="4" md="4" class="my-1 mx-1" align-self-start>
                <v-btn color="info" @click="addTextField()">テキスト追加</v-btn>
              </v-col>
              <v-col cols="4" sm="4" md="4" class="my-1 mx-1" align-self-start>
                <v-btn color="info" @click="addImageField()">画像追加</v-btn>
              </v-col>
              <v-col cols="4" sm="4" md="4" class="my-1 mx-1" align-self-start>
                <v-btn color="info" @click="addButtonField()">ボタン追加</v-btn>
              </v-col>
              <v-col cols="4" sm="4" md="4" class="my-1 mx-1" align-self-start>
                <v-btn color="info" @click="addTemplateField()">テンプレート追加</v-btn>
                <v-tooltip text="テンプレートは画像、テキスト、ボタンをセットで送れる機能です">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" style="color: pink;">mdi-help</v-icon>
                </template>
              </v-tooltip>
              </v-col>
              <v-col cols="4" sm="4" md="4" class="my-1 mx-1" align-self-start>
                <v-btn @click="dialog = false" color="warning">キャンセル</v-btn>
              </v-col>
              <v-col cols="4" sm="4" md="4" class="my-1 mx-1" align-self-start>
                <v-btn color="success" @click="updatePackage()">保存</v-btn>
              </v-col>
            </v-row>
            </v-card>
        </div>
        <div class="overflow-visible">
          <PreviewPackage :components="components"/>
        </div>
        </v-dialog>
  </v-container>
</template>

<script>
import { getFirestore, collection, query, getDocs, deleteDoc, doc, setDoc, getDoc } from 'firebase/firestore'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import PreviewPackage from './PreviewPackage.vue'

export default {
  name: 'PackageTable',
  components: {
      PreviewPackage
  },
  data() {
    return {
      packages: [],
      edit_dialog: false,
      packageName: '',
      tmpPackageName:'',
      components: [],
      oldComponents: []
    }
  },
  methods: {
    getLabelWithImagePath(index, imagePath) {
      return `${imagePath}`;
    },
    onFileChange(event, index) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const value = e.target.result;
        this.components[index].value = value; 
        this.components[index].filename = file.name;
      };
      reader.readAsDataURL(file);
    },
    addTextField() {
      this.components.push({ type: 'text', value: '' });
    },
    addImageField() {
      this.components.push({ type: 'image', value: null, filename: '' });
    },
    addButtonField() {
        this.components.push({ type: 'button', value: '', buttonname: '',trigger: '' });
    },
    addTemplateField() {
        this.components.push({ type: 'template', value: '', filename: '',title: '',subtitle: '', buttonName: '', buttonValue:''});
    },
    removeField(index) {
      this.components.splice(index, 1);
    },
  async updatePackage(){
    const db = getFirestore()
    const uid = this.$store.state.user.uid;
    const tmpPackageName = this.tmpPackageName;
    const newPackageName = this.packageName;
    const oldDocRef = doc(db, `Users/${uid}/packages/${tmpPackageName}`);
    const oldDoc = await getDoc(oldDocRef);
    if (oldDoc.exists()) {
      if (JSON.stringify(this.components) === JSON.stringify(this.oldComponents)) {
        console.log("Components have not changed, no need to update");
        return;
      }
      for (let i = 0; i < this.components.length; i++) {
        const component = this.components[i];
        if (component.type === 'text') {
          component.value = component.value.replace(/\n/g, '<br>');
        }
      }
      const newComponents = await this.uploadimage(this.components);
      await deleteDoc(oldDocRef);
      await this.createUserDocument(uid, newPackageName, newComponents);  
      this.fetchPackages(uid);
      this.edit_dialog = false;

    } else {
      console.error("ドキュメントの更新に失敗しました。");
    }
  },
  async createUserDocument(uid, title, newCompo){
    const userDocRef = doc(collection(getFirestore(), "Users"), uid);
    const packagesCollRef = collection(userDocRef, "packages");
    const packageDocRef = doc(packagesCollRef, title);
    
    for (let i = 0; i < newCompo.length; i++) {
      const component = newCompo[i];
      await setDoc(packageDocRef, {
        [i]: component,
        created_at: new Date(),
      }, { merge: true });
    }
  },
  async deletePackage(pkg) {
    const uid = this.$store.state.user.uid;
    const db = getFirestore()
    const packagesRef = collection(db, `Users/${uid}/packages`)
    try {
      await deleteDoc(doc(packagesRef, pkg))
      this.packages = this.packages.filter(p => p.id !== pkg.id)
    } catch (error) {
      console.error(error)
    }
    await this.fetchPackages(uid);
  },
  async uploadimage(components) {
    const uid = this.$store.state.user.uid;
    const updatedComponents = [];
    for (let i = 0; i < components.length; i++) {
      const component = components[i];
      if (!component) {
        alert("Please select a file.");
        return;
      }
      if (component.type === 'image' && component.value || component.type === 'template' && component.value) {
        const timestamp = Date.now();
        const name = component.filename || `${timestamp}_undefined`;
        const storageRef = ref(getStorage(), `Sendimage/${uid}/${name}`);
        
        // Always upload the file, Firebase will automatically overwrite the existing file
        const uploadTask = uploadBytesResumable(storageRef, await fetch(component.value).then(res => res.blob()));
        await uploadTask;
        const downloadURL = await getDownloadURL(storageRef);
        updatedComponents.push({
          ...component,
          value: downloadURL,
        });
      } else {
        updatedComponents.push(component);
      }
    }
    return updatedComponents;
  },
    async fetchPackages(uid) {
      const db = getFirestore();
      const packagesRef = collection(db, `Users/${uid}/packages`);
      const q = query(packagesRef);
      const querySnapshot = await getDocs(q);
      this.packages = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
    },
    editPackage(content) {
      const contentArray = this.proxyToArray(content);
      this.packageName = content.id;
      this.tmpPackageName = content.id;
      this.components = []; 
      for (let i = 0; i < contentArray.length; i++){
        if(contentArray[i].type === "text"){
           this.components.push({ type: 'text', value: contentArray[i].value });
        }else if(contentArray[i].type === "button"){
          this.components.push({ type: 'button', value: contentArray[i].value, buttonname: contentArray[i].buttonname,buttontrigger: contentArray[i].buttontrigger  });
        }else if(contentArray[i].type === "image"){
          this.components.push({ type: 'image', value: contentArray[i].value, filename: contentArray[i].filename });
        }else if(contentArray[i].type === "template"){
          this.components.push({ type: 'template', value: contentArray[i].value, filename: contentArray[i].filename, title: contentArray[i].title,
          subtitle: contentArray[i].subtitle, buttonName: contentArray[i].buttonName, buttonValue: contentArray[i].buttonValue});
        }
      }
      this.oldComponents = JSON.parse(JSON.stringify(this.components));
      this.edit_dialog = true;
    },
    proxyToArray(proxyObject) {
      const array = [];
      for (let key in proxyObject) {
        if (key !== 'id' && key !== 'created_at' && key !== 'is_valid') {
          array.push(proxyObject[key]);
        }
      }
      return array;
    }
  },
  created() {
    const uid = this.$store.state.user.uid;
    this.fetchPackages(uid);
  },
}
</script>
