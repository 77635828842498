<template>
  <v-app>
    <navigation :color="color" :flat="flat" />
    <v-main class="pt-0">
      <!--<v-banner type="info">
         価格改定のお知らせ:2980
      </v-banner>-->
      <home />
      <about />
      <download />
      <pricing />
      <contact />
    </v-main>
    <foote />
  </v-app>
</template>

<style scoped>
.v-main {
  background-image: url("~@/assets/img/bgMain.png");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
</style>

<script>
import navigation from "./HomeNavigation";
import foote from "./NewFooter";
import home from "./HomeSection";
import about from "./AboutSection";
import download from "./DownloadSection";
import contact from "./ContactSection";
import pricing from "./PricingSection";


export default {
  name: "App",

  components: {
    navigation,
    foote,
    home,
    about,
    download,
    contact,
    pricing,
  },

  data: () => ({
    fab: null,
    color: "",
    flat: null,
  }),

  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
  },

  mounted() {
  },

  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },

  methods: {
  }
};
</script>