<template>
    <v-btn color="green" class="my-2 mx-2" @click="dialog = true">作成+</v-btn>
    <v-dialog v-model="dialog" max-width="700px">
        <div class="">
            <v-card style="overflow-y: auto; max-height: 500px;">
            <v-card-title>プレゼント作成</v-card-title>
            <v-card-text>
              <h3>パッケージの名前</h3>
              <v-text-field v-model="packageName" label="Package Name"></v-text-field>
            </v-card-text>
            <v-card-text>
                <v-form>
                
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="dialog = false">キャンセル</v-btn>
                <v-btn class="danger">テキスト追加</v-btn>
            </v-card-actions>
            </v-card>
        </div>
    </v-dialog>
</template>
<script>
  export default {
    data() {
      return {
        dialog: false,
      }
    }
  }
</script>