<template>
  <v-footer dark padless>
    <v-card flat tile class="secondary white--text text-center">
      <v-card-text>
        <v-list dense>
          <v-list-item link v-for="item in items" :key="item.text">
            <v-list-item-content>
              <v-list-item-title>
                <router-link :to="item.path">{{ item.text }}</router-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title>
                <a href="https://enigo.net">運営会社</a>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-text class="white--text pt-0">
          <p>@2023 合同会社エニゴ AINOU<br/></p>
          <p>東京都渋谷区道玄坂1丁目10番8号渋谷道玄坂東急ビル2F-C</p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>AINOU</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style scoped>
.v-card {
  width: 100%;
}
</style>

<script>
export default {
  data: () => ({
      items: [
        { text: 'プライバシーポリシー', path: '/privacy_policy' },
        { text: 'サービス規約', path: '/terms_of_service' },
        { text: '特定商取引法', path: '/commercial_law' },
        { text: 'お問い合わせ', path: '/site_inquiry_form' },
      ],
  }),
};
</script>
