<template>
  <div class="my-5 mx-2">
    <router-link to="/">ホームへ戻る<v-icon style="color: black;">mdi-home</v-icon></router-link>
  </div> 
  <v-container>
    <v-card>
      <v-card-title class="headline">プライバシーポリシー</v-card-title>
      <v-card-text>
        <p>当サイトのプライバシーポリシーについてご説明します。</p>
        <h2>収集する情報</h2>
        <p>当サイトでは、以下の情報を収集する場合があります。</p>
        <ul>
          <li>メールアドレス</li>
          <li>氏名</li>
        </ul>
        <h2>情報の利用</h2>
        <p>当サイトでは、収集した情報を以下の目的で利用することがあります。</p>
        <ul>
          <li>お客様へのサービス提供のため</li>
          <li>お客様からのお問い合わせに対応するため</li>
          <li>当サイトの改善に役立てるための分析</li>
        </ul>
        <h2>情報の共有</h2>
        <p>当サイトでは、収集した情報を以下の場合を除き、第三者に開示することはありません。</p>
        <ul>
          <li>お客様の同意がある場合</li>
          <li>法律に基づく場合</li>
          <li>当サイトの運営に必要な場合</li>
        </ul>
        <h2>Cookie（クッキー）の使用</h2>
        <p>当サイトでは、Cookieを使用して、お客様により良いサービスを提供するために以下の目的で利用することがあります。</p>
        <ul>
          <li>お客様がサイト内での設定を保持するため</li>
          <li>アクセス状況の分析のため</li>
        </ul>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
};
</script>

<style scoped>
</style>
