import { createRouter, createWebHistory } from 'vue-router'
import store from '../plugins/store'
import PrivacyPolicy from '../views/PrivacyPolicy'
import TermsOfService from '../views/TermsOfService'
import AboutPage from '../views/AboutPage'
import AdminIndex from '../views/admin/AdminIndex'
import UserSettings from "../views/admin/UserSettings.vue";
import PaymentSettings from "../views/admin/PaymentSettings.vue";
import InstagramSettings from "../views/admin/InstagramSettings.vue";
import PackageManagement from "../views/admin/PackageManagement.vue";
import CrsateToken from "../views/admin/CreateToken.vue";
import giftManagement from "../views/admin/giftManagement.vue";
import SignIn from '../views/authentication/SignIn'
import SignUp from '../views/authentication/SignUp'


const routes = [
  {
    path: '/privacy_policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/terms_of_service',
    name: 'TermsOfService',
    component: TermsOfService
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage
  },
  {
    path: '/admin',
    name: 'AdminIndex',
    component: AdminIndex,
    meta: { requiresAuth: true },
    children: [
        {
          path: 'package_management',
          name: 'PackageManagement',
          component: PackageManagement,
        },
        {
            path: 'payment_settings',
            name: 'PaymentSettings',
            component: PaymentSettings,
        },
        {
            path: 'user_settings',
            name: 'UserSettings',
            component: UserSettings,
        },
        {
            path: 'create_token',
            name: 'CrsateToken',
            component: CrsateToken,
        },
        {
            path: 'gift_management',
            name: 'giftManagement',
            component: giftManagement,
        },
        {
          path: 'instagram_settings',
          name: 'InstagramSettings',
          component: InstagramSettings,
        },
    ],
},
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn,
    props: true
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const isLoggedIn = store.getters.isLoggedIn
  if (requiresAuth && !isLoggedIn) {
    next('/signin')
  } else {
    next()
  }
})

export default router