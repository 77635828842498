<template>
  <section id="about">
    <v-container fluid>
        <v-row align="center" justify="center">
            <v-col cols="10">
                <v-row align="center" justify="center">
                    <v-col cols="12" md="7">
                        <h1 class="font-weight-light display-2"></h1>
                        <h1 class="font-weight-light display-1 mb-3">
                            様々な場面で大活躍
                        </h1>
                        <v-row>
                            <v-col cols="12" class="d-flex align-center">
                                <v-img
                                    :src="Image2"
                                    max-width="60px"
                                    class="mr-4"
                                />
                                <div>
                                    <h3>カスタマーサポート、サクセスに</h3>
                                    <p class="text-justify">
                                        チャットボットを活用することで、自動応答によりユーザーによる自己解決が促されるため、問い合わせ件数を削減することができます。<br/>
                                        コールセンターを24時間365日体制にすることができるため、オペレーターの稼働時間に関わらず、ユーザーからの問い合わせにいつでも対応できるようになります。
                                    </p>
                                </div>
                            </v-col>
                            <v-col cols="12" class="d-flex align-center">
                                <v-img
                                    :src="Image4"
                                    max-width="60px"
                                    class="mr-4"
                                />
                                <div>
                                    <h3>商品販促、営業、接客に</h3>
                                    <p class="text-justify">
                                        AINOUは非営業時間であっても働きます。<br/>例えばランディングページにAINOUから発行されるたった数行のコードをコピーし埋め込むだけで、
                                        よりユーザーにとって価値のある情報を効果的に伝えることができ、顧客満足度の向上やコンバージョン率の増加が期待されます。
                                    </p>
                                </div>
                            </v-col>
                            <v-col cols="12" class="d-flex align-center">
                                <v-img
                                    :src="Image3"
                                    max-width="60px"
                                    class="mr-4"
                                />
                                <div>
                                    <h3>社内ヘルプデスクに</h3>
                                    <p class="text-justify">
                                        社内からの問い合わせが事業の大きな負担となっているケースは多々見受けられます。<br/>
                                        イントラネット上にマニュアルやFAQを掲示するケースは珍しくありませんが、
                                        思うように利用してもらえず、結局電話やメールで問い合わせが来るということが多いのではないでしょうか？<br/>
                                        AINOUを利用し手軽に従業員の自己解決を支援できます。
                                    </p>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="5" class="d-none d-md-flex">
                        <v-img
                            :src="Image1"
                            class="d-block ml-auto mr-auto"
                            max-width="400px"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
  </section>
</template>
<script>
export default {
  data() {
    return {
      Image1: require('@/assets/img/ill1.svg'),
      Image2: require('@/assets/img/icon1.svg'),
      Image3: require('@/assets/img/icon2.svg'),
      Image4: require('@/assets/img/icon3.svg'),

    }
  }
}
</script>

<style scoped>
#about {
  background-color: #ffffff;
}
</style>
