<template>
  <div>
    <v-text-field 
      ref="newEmailField"
      class="my-5 mx-2"
      v-model="newEmail"
      label="Emailアドレス"
      :rules="[requiredRule, emailRule]"
    ></v-text-field>
    <v-btn class="my-5 mx-2" @click="open">メール変更をする</v-btn>
    <v-btn class="my-2 mx-2" @click="open2">パスワード変更をする</v-btn>

    <!-- Added Leave Service Button -->
    <v-btn color="red" dark class="my-5 mx-2" @click="leaveService">退会</v-btn>

    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title>現在のパスワードを記載してください</v-card-title>
        <v-card-text>
          <v-text-field 
            ref="currentPasswordFieldForEmail"
            v-model="currentPassword"
            label="Current Password"
            type="password"
            :rules="[requiredRule, passwordLengthRule]"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="updateEmailAction">メールを変更する</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2">
      <v-card>
        <v-card-title>現在のパスワードを記載してください</v-card-title>
        <v-card-text>
          <v-text-field 
            ref="currentPasswordFieldForEmail"
            v-model="currentPassword"
            label="Current Password"
            type="password"
            :rules="[requiredRule, passwordLengthRule]"
          ></v-text-field>
          <v-text-field 
            v-model="newPassword"
            label="新しいパスワード"
            type="password"
            :rules="[requiredRule, passwordLengthRule]"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="updatePassword">パスワードを変更する</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getAuth, updateEmail, EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth'
export default {
  data() {
    return {
      newEmail: this.$store.state.user.email,
      newPassword: '',
      currentPassword: '',
      dialog: false,
      dialog2: false,
      requiredRule: v => !!v || '必須項目です',
      emailRule: v => /.+@.+/.test(v) || '正しいEメール形式で入力してください',
      passwordLengthRule: v => (v && v.length >= 8) || 'パスワードは8文字以上で入力してください',
    }
  },
  methods: {
    open(){
      this.dialog = true;
    },
    open2(){
      this.dialog2 = true;
    },
    async updateEmailAction() {
      let isValidEmail = this.$refs.newEmailField.validate();
      let isValidCurrentPasswordForEmail = this.$refs.currentPasswordFieldForEmail.validate();

      if (!isValidEmail) this.$refs.newEmailField.error = true;
      if (!isValidCurrentPasswordForEmail) this.$refs.currentPasswordFieldForEmail.error = true;

      if (!isValidEmail || !isValidCurrentPasswordForEmail) return;
      const auth = getAuth();
      const user = auth.currentUser;
      const password = this.currentPassword;
      const credential = await EmailAuthProvider.credential(
        user.email,
        password
      );
      try {
        await reauthenticateWithCredential(user,credential);
        await updateEmail(user, this.newEmail);
        this.dialog = false;
      } catch (error) {
        // エラーが発生
        console.log(error);
      }
    },
    async updatePassword() {
      let isValidNewPassword = this.$refs.newPasswordField.validate();
      let isValidCurrentPasswordForPassword = this.$refs.currentPasswordFieldForPassword.validate();

      if (!isValidNewPassword) this.$refs.newPasswordField.error = true;
      if (!isValidCurrentPasswordForPassword) this.$refs.currentPasswordFieldForPassword.error = true;

      if (!isValidNewPassword || !isValidCurrentPasswordForPassword) return;
      const auth = getAuth();
      const user = auth.currentUser;
      const password = this.currentPassword;
      const credential = await EmailAuthProvider.credential(
        user.email,
        password
      );
      await reauthenticateWithCredential(user,credential);
      await updatePassword(user, this.newPassword);
      this.dialog = false;
    },
    leaveService() {
      this.$router.push({ path: '/leave_service' });
    }
  },
}
</script>