import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, FacebookAuthProvider, signInWithPopup, setCustomUserClaims, signOut, updateEmail, updatePassword,EmailAuthProvider,reauthenticateWithCredential, GoogleAuthProvider  } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getStorage} from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAdu8D0QFqXS-CCtB0fkfDlZX4FuBa-pZA",
    authDomain: "steppin-384017.firebaseapp.com",
    projectId: "steppin-384017",
    storageBucket: "steppin-384017.appspot.com",
    messagingSenderId: "553963729827",
    appId: "1:553963729827:web:da82aaa4258482b1cea016",
    measurementId: "G-VVC7ZEQMN9"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const firestore = getFirestore(app); 
const storage = getStorage(app);


export { auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, FacebookAuthProvider, signInWithPopup, setCustomUserClaims, signOut,updateEmail, updatePassword,EmailAuthProvider,reauthenticateWithCredential, database, firestore, storage, GoogleAuthProvider};
