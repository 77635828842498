<template>
    <v-card
      class="mx-auto"
      max-width="400"
      tile
      style="margin: 10px; padding: 10px;"
    >
    <div id="fb-root"></div>
    <div v-if="(!isUserLoggedIn) && (paymentStatus === 0)">
      <p style="margin-bottom: 10px;">未連携 <v-icon style="color: red;">mdi-bookmark-remove</v-icon></p>
      <!--<v-btn color="primary" @click="loginWithFacebook">Facebookログイン</v-btn>-->
      <v-btn @click="loginWithFacebook">
        <img src="/facebooklogo.png" alt="icon" class="mx-2" style="height: 30px;">
            Facebookログインして連携する
      </v-btn>
    </div>
    <div v-else style="margin: 10px; padding: 10px;">
      <p style="margin-bottom: 10px;">連携済み <v-icon style="color: green;">mdi-bookmark-check</v-icon></p>
      <div style="margin-bottom: 10px;">
        <!--<v-btn color="primary" @click="loginWithFacebook">
          facebook再連携
        </v-btn>-->
      <v-btn @click="loginWithFacebook">
      <img src="/instagram.svg" alt="icon" class="mx-2" style="height: 30px;">
            Facebookログインして再連携する
      </v-btn>
        <p v-if="isOneMonthLeft" style="color: red;">
          期限が迫っています。再連携してください。
        </p>
      </div>
      <v-btn color="red" @click="logout">連携解除</v-btn>
    </div>
   </v-card>
</template>

<script>
import { getFirestore, collection, doc, setDoc, getDoc } from 'firebase/firestore';
export default {
  data() {
    return {
      isUserLoggedIn: false,
      instagramId: '',
      userAccessToken: '',
      pageAccessToken: '',
      pageId: '',
      mediaIds:[],
      paymentStatus: null
    };
    },
  props: {
    propertyName: {
      type: String, // プロパティの型を指定します
      required: true // プロパティが必須かどうかを指定します
    },
    creationTime: { 
      type: Number,
      required: false
    }
  },
  computed: {
    isOneMonthLeft() {
    const oneMonthInMilliseconds = 30 * 24 * 60 * 60 * 1000; // 1 month in milliseconds
    const now = Date.now();
    const timePassed = now - this.creationTime * 1000;

    return timePassed >= 0 && timePassed <= oneMonthInMilliseconds;
    }
  },
  async mounted() {
    const script = document.createElement('script');
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    script.async = true;
    script.defer = true;
    script.crossOrigin = 'anonymous';
    script.onload = this.initializeFacebookSDK;
    document.head.appendChild(script);

    const client_id = this.$store.state.user.uid
    const userDocRef = doc(collection(getFirestore(), "Users"), client_id);
    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      this.paymentStatus = data.paymentStatus;
      console.log(this.paymentStatus );
    } else {
      console.log("No such document!");
    }
  },
  methods: {
    initializeFacebookSDK() {
      window.FB.init({
        appId: '148540271275522',
        cookie: true,
        xfbml: true,
        version: 'v13.0'
      });
    },
    loginWithFacebook() {
      this.requestPermissions();
    },
    requestPermissions() {
      const permissions = ['instagram_basic','pages_messaging', 'pages_show_list','pages_read_engagement','pages_manage_metadata','instagram_manage_comments'];
      const scope = permissions.join(',');
      window.FB.login(response => {
        if (response.authResponse) {
          this.isUserLoggedIn = true;
          const accessToken = response.authResponse.accessToken;
          console.log(accessToken);
          console.log(response);

          this.getLongTermAccessToken(accessToken)
            .then(longTermAccessToken => {
              this.userAccessToken = longTermAccessToken;
              console.log(this.userAccessToken);
              return this.getPageId(longTermAccessToken)
                .then(pageId => {
                  this.pageId = pageId;
                  console.log(this.pageId);
                  return this.getPageAccessToken(pageId, longTermAccessToken);
                });
              })
              .then(pageAccessToken => {
                this.pageAccessToken = pageAccessToken;
                console.log(this.pageAccessToken);
                return this.getInstagramId(this.pageId, pageAccessToken);
            })
            .then(instagramId => {
              this.instagramId = instagramId;
              console.log(instagramId);
              return this.getMediaId(this.instagramId, this.pageAccessToken);
            })
            .then(mediaId => {
              this.mediaIds = mediaId.data.map(item => item.id);
              console.log(this.mediaIds);
              this.subscribeWebhook(this.pageAccessToken, this.pageId);
              this.setSomeValue();
              this.connectInstagramId(this.instagramId);
              this.connectMediaIds(this.mediaIds);
              this.isUserLoggedIn = true;
              window.location.reload(true);
            })
            .catch(error => {
              console.error(error);
            });
        } else {
          this.isUserLoggedIn = false;
        }
      },
      { scope: scope }
      );
    },
    getLongTermAccessToken(shortTermAccessToken) {
      return new Promise((resolve, reject) => {
        window.FB.api('/oauth/access_token', {
          grant_type: 'fb_exchange_token',
          client_id: '148540271275522',
          client_secret: 'eab52863eccef1ce01188f60640ce3d6',
          fb_exchange_token: shortTermAccessToken
        }, (response) => {
          if (response.access_token) {
            const longTermAccessToken = response.access_token;
            resolve(longTermAccessToken);
          } else {
            reject(response.error);
          }
        });
      });
    },
    getPageId(longTermAccessToken) {
      return new Promise((resolve, reject) => {
        window.FB.api('/me/accounts', {
          access_token: longTermAccessToken
        }, (response) => {
          if (response.data && response.data.length > 0) {
            const pageId = response.data[0].id;
            resolve(pageId);
          } else {
            reject(response.error);
          }
        });
      });
    },
    getPageAccessToken(pageId, longTermAccessToken) {
      return new Promise((resolve, reject) => {
        window.FB.api(`/${pageId}`, {
          access_token: longTermAccessToken,
          fields: 'access_token'
        }, (response) => {
          if (response.access_token) {
            const pageAccessToken = response.access_token;
            resolve(pageAccessToken);
          } else {
            reject(response.error);
          }
        });
      });
    },
    getInstagramId(pageId, accessToken) {
      return new Promise((resolve, reject) => {
        window.FB.api(`/${pageId}`, {
          fields: 'instagram_business_account',
          access_token: accessToken
        }, (response) => {
          if (response.instagram_business_account) {
            console.log(response);
            const instagramAccountId = response.instagram_business_account.id
            resolve(instagramAccountId);
          } else {
            reject(response.error);
          }
        });
      });
    },
    getMediaId(instagramId, pageAccessToken) {
      return new Promise((resolve, reject) => {
        window.FB.api(`/${instagramId}/media`, {
          access_token: pageAccessToken
        }, (response) => {
          if (response.data) {
            console.log(response);
            //const instagramAccountId = response.instagram_business_account.id
            resolve(response);
          } else {
            reject(response.error);
          }
        });
      });
    },
    subscribeWebhook(pageAccessToken, pageId) {
      console.log(pageId);
      window.FB.api('/' + pageId + '/subscribed_apps', 'POST', {
        access_token: pageAccessToken,
        subscribed_fields: 'feed',
      }, function(response) {
        if (response.success) {
          console.log('Webhook subscription created successfully!');
        } else {
          console.error('Failed to create webhook subscription:', response.error);
        }
      });
    },
    async setSomeValue() {
      const uid = this.$store.state.user.uid;
      let currentDate = new Date(); 
      try {
        const userDocRef = doc(getFirestore(), "Users", uid);
        await setDoc(userDocRef, {
          instagramId: this.instagramId,
          userAccessToken: this.userAccessToken,
          pageAccessToken: this.pageAccessToken,
          pageId: this.pageId,
          paymentStatus: 2,
          expiredAt: currentDate.setMonth(currentDate.getMonth() + 3),
          mediaCreated: currentDate,
          customerId: ''
        }
        ,{ merge: true }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async connectInstagramId(instagramId) {
      const uid = this.$store.state.user.uid;
      const firestore = getFirestore();
      const connectionCollectionRef = collection(firestore, 'Connection');
      const instagramIdDocRef = doc(connectionCollectionRef, instagramId);
      const instagramIdDocSnapshot = await getDoc(instagramIdDocRef);
      if (instagramIdDocSnapshot.exists()) {
        // instagramIdドキュメントが存在する場合
        const userRef = doc(collection(firestore, 'Users'), uid);
        await setDoc(instagramIdDocRef, { userRef }, { merge: true });
      } else {
        // instagramIdドキュメントが存在しない場合
        const data = {
          userRef: doc(collection(firestore, 'Users'), uid)
        };
        await setDoc(instagramIdDocRef, data);
      }
    },
    async connectMediaIds(mediaIds) {
      const uid = this.$store.state.user.uid;
      const firestore = getFirestore();
      const connectionCollectionRef = collection(firestore, 'MediaIds');
      const userRef = doc(collection(firestore, 'Users'), uid);
      for (let id of mediaIds) {
        // FirestoreのドキュメントIDは文字列である必要があります
        let docId = id.toString();

        // MediaIds コレクションに新しいドキュメントを作成
        const mediaIdDocRef = doc(connectionCollectionRef, docId);

        // ドキュメントに userRef を保存
        await setDoc(mediaIdDocRef, {
          userRef: userRef
        }, { merge: true });
      }
    },
    logout() {
      // ログアウトの処理を実装します
      window.FB.logout(response => {
        if (response) {
          // ログアウト成功
          this.isUserLoggedIn = false;
          this.instagramId = '';
          this.userAccessToken = '';
          this.pageAccessToken = '';
          this.pageId = '';
          console.log('ログアウトしました');
        } else {
          // ログアウト失敗
          console.error('ログアウトに失敗しました');
        }
      });
    },
  }
}
</script>

