<template>
  <v-container>
    <v-row>
      <v-col cols="10">
        <v-table>
          <thead>
            <tr>
              <th class="text-left">
                名前
              </th>
              <th class="text-left">
                ステータス
              </th>
              <th class="text-left">
                編集
              </th>
              <th class="text-left">
                削除
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="setting in settings" :key="setting.id">
              <td>{{ setting.id }}</td>
             <td>
                <span v-if="setting.is_valid"><i class="mdi mdi-check"></i></span>
                <span v-else><i class="mdi mdi-minus"></i></span>
              </td>
              <td><v-icon small @click="editSetting(setting)">mdi-pencil</v-icon></td>
              <td><v-icon small @click="deleteSetting(setting.id)">mdi-delete</v-icon></td>
            </tr>
          </tbody>
        </v-table>
      </v-col>
    </v-row>
    <v-dialog v-model="edit_dialog" max-width="700px">
      <v-card style="overflow-y: auto; max-height: 800px;">
        <v-card-title>自動返信設定</v-card-title>
        <v-card-text>
          <v-col cols="12">
            <v-text-field v-model="replyName" label="自動返信名"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select v-model="type" item-title="text" item-value="value" :items="replyType" label="自動返信の種類"></v-select>
          </v-col>
        <v-row>
          <v-col cols="12">
            <v-radio-group v-model="periodOption">
              <v-radio label="期間指定なし" value="none"></v-radio>
              <v-radio label="期間指定あり" value="custom"></v-radio>
            </v-radio-group>
          </v-col>
        <v-col cols="12" v-if="periodOption === 'custom'">
            <div>開始日</div>
            <Datepick v-model="startDate" label="終了日"></Datepick>
            <div>終了日</div>
            <Datepick v-model="endDate" label="終了日"></Datepick>
        </v-col>
        </v-row>
        <v-col cols="12">
          <v-select v-model="pack" :items="packages" label="返信パッケージ"></v-select>
        </v-col>
        <v-col cols="12">
          <v-switch v-model="is_valid" label="有効、無効"></v-switch>
        </v-col>
        <v-row>
          <v-col cols="9">
            <v-text-field v-model="keywordInput" label="返信条件、キーワード追加"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-btn @click="addLabel">追加</v-btn>
          </v-col>
          <v-row>
            <v-col cols="2" v-for="(label, i) in keywords" :key="i">
              <v-chip close @click="removeLabel(i)">{{ label }}<span class="close-icon">x</span></v-chip>
            </v-col>
          </v-row>
        </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary"  @click="save">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { getFirestore, collection, query, getDocs, deleteDoc, doc, setDoc } from 'firebase/firestore'
import { reactive } from 'vue';
import Datepick from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: 'SettingsTable',
  components: {
       Datepick
  },
  data() {
    return {
      replyType: [
        { text: 'ストーリーズメンション', value: 0 },
        { text: '投稿コメント(リール、liveを含む)', value: 1 }
      ],
      type: null,
      settings: reactive([]),
      uid: null,
      edit_dialog: false,
      replyName: '',
      tmpReplyName: '',
      startDate: null,
      endDate: null,
      periodOption: null,
      is_valid: null,
      packages: [],
      pack: null,
      keywords: [],
      keywordInput: '',
      }
    },
  methods: {
      async save() {
          const uid = this.$store.state.user.uid;
          const replyName = this.replyName;
          const packages = this.packages;
          const tmpReplySettingsName = this.tmpReplySettingsName;
          const tmpPackageName = doc(getFirestore(), `Users/${uid}/replySettings/${tmpReplySettingsName}`);
          if (!replyName || packages.length === 0) {
            console.log('必須項目を入力してください');
            return; 
          }
          await deleteDoc(tmpPackageName);
          await this.createReplyContents(uid,replyName);
          this.fetchSettings(uid);
          this.edit_dialog = false;
      },
      async createReplyContents(uid, replyName) {
        const pack = this.pack;
        const keywords = this.keywords;
        const packagesRef = collection(doc(getFirestore(), 'Users', uid), 'packages');
        const promotionRef = doc(packagesRef, pack);
        const userDocRef = doc(collection(getFirestore(), "Users"), uid);
        const replySettingsCollRef = collection(userDocRef, "replySettings");
        const package1DocRef = doc(replySettingsCollRef, replyName);
        await Promise.all([
          setDoc(package1DocRef, {
            package: promotionRef,
            keywords: keywords,
            is_valid: this.is_valid,
            periodOption: this.periodOption,
            startDate: this.startDate,
            endDate: this.endDate,
            replyType: this.type
          }, { merge: true }),
          setDoc(userDocRef, { hasPackages: true }, { merge: true }),
        ]);
      },
    addLabel() {
      if (this.keywordInput !== '') {
        this.keywords.push(this.keywordInput);
        this.keywordInput = '';
      }
    },
    removeLabel(index) {
      this.keywords.splice(index, 1);
    },
    async deleteSetting(pkg) {
      const uid = this.$store.state.user.uid;
      const db = getFirestore()
      const settingsRef = collection(db, `Users/${uid}/replySettings`)
      try {
        await deleteDoc(doc(settingsRef, pkg))
        this.settings = this.settings.filter(s => s.id !== pkg)
      } catch (error) {
        console.error(error)
      }
    },
    async editSetting(content){
      this.pack = content.package.id;
      this.edit_dialog = true;
      this.replyName = content.id;
      this.tmpReplySettingsName = content.id;
      this.periodOption = content.periodOption;
      this.type =  this.replyType.find(item => item.value === content.replyType);
      if(this.periodOption === "custom"){
        this.startDate = content.startDate.toDate()
        this.endDate = content.endDate.toDate()
      }
      this.keywords = [...content.keywords];
      this.is_valid = content.is_valid;
      try {
          const user = this.$store.state.user;
          const packagesRef = collection(doc(getFirestore(), 'Users', user.uid), 'packages');
          const querySnapshot = await getDocs(packagesRef);
          const ids = querySnapshot.docs.map(doc => doc.id);
          this.packages = ids;
      } catch (error) {
          console.error(error);
      }
    },
    async fetchSettings(uid) {
      const db = getFirestore()
      const settingsRef = collection(db, `Users/${uid}/replySettings`)
      const q = query(settingsRef)
      const querySnapshot = await getDocs(q)
      this.settings = querySnapshot.docs.map((doc) => {
        const data = doc.data()
        return { id: doc.id, ...data }
      })
      console.log(this.settings);
    },
    proxyToArray(proxyObject) {
      const array = [];
      for (let key in proxyObject) {
        if (key !== 'id' && key !== 'created_at' && key !== 'is_valid') {
          array.push(proxyObject[key]);
        }
      }
      return array;
    }
  },
  created() {
    const uid = this.$store.state.user.uid;
    this.fetchSettings(uid);
  },
}
</script>