<template>
  <v-btn color="green" class="my-2 mx-2" @click="dialog = true">作成+</v-btn>
  <mediaButton :instagram-id="instagramId" :page-access-token="pageAccessToken"/>
  <v-tooltip text="メディア同期することで、安全に自動返信をすることが可能となります">
  <template v-slot:activator="{ props }">
    <v-icon v-bind="props" style="color: pink;">mdi-help</v-icon>
  </template>
</v-tooltip>
  <v-alert
    v-if="message"
    :value="true"
    type="success"
    dismissible
  >
    {{ message }}
  </v-alert>
  <v-dialog v-model="dialog" max-width="700px">
    <v-card style="overflow-y: auto; max-height: 800px;">
      <v-card-title>自動返信設定</v-card-title>
      <v-card-text>
        <v-col cols="12">
          <v-text-field v-model="replyName" label="自動返信名"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-select v-model="type" item-title="text" item-value="value" :items="replyType" label="自動返信の種類"></v-select>
        </v-col>
      <v-row>
        <v-col cols="12">
          <v-radio-group v-model="periodOption">
            <v-radio label="期間指定なし" value="none"></v-radio>
            <v-radio label="期間指定あり" value="custom"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" v-if="periodOption === 'custom'">
            <div>開始日</div>
            <Datepick v-model="startDate" label="終了日"></Datepick>
            <div>終了日</div>
            <Datepick v-model="endDate" label="終了日"></Datepick>
        </v-col>
      </v-row>
      <v-col cols="12">
        <v-select v-model="pack" :items="packages" label="返信パッケージ"></v-select>
      </v-col>
      <v-col cols="12">
         <v-switch v-model="is_valid" label="有効、無効"></v-switch>
      </v-col>
      <v-row>
        <v-col cols="9">
          <v-text-field v-model="keywordInput" label="返信条件、キーワード追加"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-btn @click="addLabel">追加</v-btn>
        </v-col>
        <v-row>
          <v-col cols="3" v-for="(label, i) in keywords" :key="i">
            <v-chip close @click="removeLabel(i)">{{ label }}<span class="close-icon">x</span></v-chip>
          </v-col>
        </v-row>
      </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="save">保存</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <SettingsTable ref="settingsTable"/>
</template>


  <script>
  import { getFirestore, collection, doc, setDoc, getDocs, getDoc } from 'firebase/firestore';
  import SettingsTable from './SettingsTable.vue'
  import Datepick from '@vuepic/vue-datepicker'
  import '@vuepic/vue-datepicker/dist/main.css'
  import MediaButton from '../parts/mediaButton.vue'


  export default {
    components: {
       SettingsTable,
       Datepick,
       MediaButton
    },
    data() {
      return {
        replyType: [
          { text: 'ストーリーズメンション', value: 0 },
          { text: '投稿コメント(リール、liveを含む)', value: 1 }
        ],
        type: null,
        dialog: false,
        replyName: '',
        periodOption: 'none',
        startDate: null,
        endDate: null,
        period: null,
        packages: [],
        keywords: [],
        keywordInput: '',
        pack: null,
        is_valid: false,
        message: '',
        instagramId:'',
        pageAccessToken:'',

      }
    },
    methods: {
      async save() {
          const uid = this.$store.state.user.uid;
          const replyName = this.replyName;
          const packages = this.packages;
          if (!replyName || packages.length === 0) {
            console.log('必須項目を入力してください');
            return; 
          }
          await this.createReplyContents(uid,replyName);
          this.$refs.settingsTable.fetchSettings(uid);
          this.showMessage('作成しました', 3000); 
          this.dialog = false;
      },
      showMessage(message, duration) {
        this.message = message;
        setTimeout(() => {
          this.message = '';
        }, duration);
      },
      addLabel() {
        if (this.keywordInput !== '') {
          this.keywords.push(this.keywordInput);
          this.keywordInput = '';
        }
      },
      removeLabel(index) {
        this.keywords.splice(index, 1);
      },
      async createReplyContents(uid, replyName) {
        const pack = this.pack;
        const keywords = this.keywords;
        const packagesRef = collection(doc(getFirestore(), 'Users', uid), 'packages');
        const promotionRef = doc(packagesRef, pack);
        const userDocRef = doc(collection(getFirestore(), "Users"), uid);
        const replySettingsCollRef = collection(userDocRef, "replySettings");
        const package1DocRef = doc(replySettingsCollRef, replyName);
        await Promise.all([
          setDoc(package1DocRef, {
            package: promotionRef,
            keywords: keywords,
            is_valid: this.is_valid,
            periodOption: this.periodOption,
            startDate: this.startDate,
            endDate: this.endDate,
            replyType: this.type
          }, { merge: true }),
          setDoc(userDocRef, { hasPackages: true }, { merge: true }),
        ]);
      }
    },
    computed: {

    },
    async mounted(){
      try {
        const db = getFirestore();
        const user = this.$store.state.user;
        const userDocRef = doc(db, 'Users', user.uid);
        getDoc(userDocRef).then((docSnap) => {
          if (docSnap.exists()) {
            const docData = docSnap.data();
            this.instagramId = docData.instagramId;
            this.pageAccessToken = docData.pageAccessToken;
            const packagesRef = collection(userDocRef, 'packages');
            getDocs(packagesRef).then((querySnapshot) => {
              const ids = querySnapshot.docs.map(doc => doc.id);
              this.packages = ids;
            });
          } else {
            console.log("No such document!");
          }
        }).catch((error) => {
          console.log("Error getting document:", error);
        });

      } catch (error) {
          console.error(error);
      }
    }
  }
  </script>
  <style scoped>
  .close-icon {
    margin-left: 4px;
    cursor: pointer;
  }
.date-picker-container .vdp-datepicker__input {
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 4px;
  padding: 6px 10px;
}
  </style>
