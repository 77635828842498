<template>
  <section id="hero">
    <v-parallax dark :src="Image1" height="650">
        <v-row align="center" justify="center" class="fill-height">
            <v-col cols="10">
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6" xl="8">
                        <h1 class="display-2 font-weight-bold mb-4" style="color: black;">インスタグラムのDMを完全自動化</h1>
                        <h2 class="font-weight-light" style="color: black;">
                            ChatGPTのAIモデルを使用<br/>
                            独自チャットボットをあなたへ
                            <br/>
                        </h2>
                        <v-btn
                            rounded
                            outlined
                            large
                            dark
                            @click="$vuetify.goTo('#features')"
                            class="mt-5"
                        >
                            無料ではじめる
                            <v-icon class="ml-2">mdi-arrow-down</v-icon>
                        </v-btn>
                        <div class="mt-3 text-center sales-container" style="color: black;">
                          <div class="my-5 sales-item" style="border-bottom: 2px solid yellow; display: inline-block; padding-bottom: 5px;">AIチャットボット業界最安値</div>
                          <div class="my-5 sales-item" style="border-bottom: 2px solid yellow; display: inline-block; padding-bottom: 5px;">導入に余計な見積は一切なし</div>
                          <div class="my-5 sales-item" style="border-bottom: 2px solid yellow; display: inline-block; padding-bottom: 5px;">シンプルなUIで誰でも使える</div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" xl="4" class="hidden-sm-and-down"></v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-parallax>


    <v-container fluid id="features" class="mt-2">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="space-around">
            <v-col
              cols="12"
              sm="4"
              class="text-center"
              v-for="(feature, i) in features"
              :key="i"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card"
                  shaped
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <v-img
                    :src="feature.img"
                    max-width="200px"
                    max-height="400px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-efect': hover }"
                  ></v-img>
                  <h1 class="font-weight-regular">{{ feature.title }}</h1>
                  <h4 class="font-weight-regular subtitle-1">
                    {{ feature.text }}
                  </h4>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      Image1: require('@/assets/img/bgHero.svg'),
      Image2: require('@/assets/img/borderWaves.svg'),
      dialog: false,
      features: [
        {
          img: require("@/assets/img/Reel.png"),
          title: "Reelの自動返信",
          text: "",
        },
        {
          img: require("@/assets/img/Live.png"),
          title: "Instagram Liveの自動返信",
          text: "",
        },
        {
          img: require("@/assets/img/Feed.png"),
          title: "フィード投稿への自動返信",
          text: "",
        },
      ],
    };
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.pause();
      }
    },
  },
  methods: {
    ready(event) {
      this.player = event.target;
    }
  },
};
</script>

<style lang="scss">
.circle {
  stroke: white;
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  -webkit-transition: all 0.5s ease-in-out;
  opacity: 0.3;
}

.playBut {
  /*  border: 1px solid red;*/
  display: inline-block;
  -webkit-transition: all 0.5s ease;

  .triangle {
    -webkit-transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: white;
    transform: translateY(0);
  }

  &:hover {
    .triangle {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: white;
      animation: nudge 0.7s ease-in-out;

      @keyframes nudge {
        0% {
          transform: translateX(0);
        }
        30% {
          transform: translateX(-5px);
        }
        50% {
          transform: translateX(5px);
        }
        70% {
          transform: translateX(-2px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }

    .circle {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
}
</style>

<style>
.btn-play {
  transition: 0.2s;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
 z-index: 5;
}

.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
section {
  position: relative;
}

.sales-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.sales-item {
  margin: 0 10px;
}

@media (max-width: 767px) {
  .sales-container {
    flex-direction: column;
    align-items: center;
  }

  .sales-item {
    margin: 10px 0;
  }
}
</style>
