<template>
  <div class="my-5 mx-2">
    <v-btn color="primary" text to="/"><v-icon>mdi-home</v-icon>Home</v-btn>
  </div> 
  <v-container>
    <v-alert
      v-if="message"
      :value="true"
      type="success"
      dismissible
      @input="clearMessage"
    >
      {{ message }}
    </v-alert>
  <v-row justify="center">
    <v-col cols="12" md="8">
      <v-card>
        <v-card-title class="text-center">ログイン</v-card-title>
        <!--
        <v-btn @click="loginWithGoogle">
          <img src="/google.svg" alt="icon" class="mx-1">
              Google でログイン
        </v-btn>
        -->
        <v-card-text>
          <v-form @submit.prevent="login" ref="loginForm" class="my-5 mx-2">
            <v-text-field 
              v-model="email" 
              label="メールアドレス" 
              type="email"
              :rules="emailRules"
              required
            ></v-text-field>
            <v-text-field 
              v-model="password" 
              label="パスワード" 
              type="password"
              :rules="passwordRules"
              required
            ></v-text-field>
            <v-btn type="submit" color="primary">ログイン</v-btn>
          </v-form>
          <div class="mt-2">
            <router-link to="/reset_password">パスワードをお忘れですか？</router-link>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
import { ref, watchEffect } from 'vue';
import { auth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup  } from '@/firebaseInit.js';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

export default {
  name: "SignIn",
  computed: {
    message() {
      return this.$store.state.message;
    },
  },
  setup() {
    const email = ref('');
    const password = ref('');
    const router = useRouter();
    const store = useStore();

    const clearMessage = () => {
      store.commit('clearMessage');
    };

    const emailRules = [
      v => !!v || 'Emailは必須です',
      v => /.+@.+\..+/.test(v) || 'Emailの形式が正しくありません'
    ];

    const passwordRules = [
      v => !!v || 'パスワードは必須です',
      v => (v && v.length >= 8) || 'パスワードは8文字以上である必要があります'
    ];

    const login = async () => {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email.value, password.value);
        const userDocRef = doc(getFirestore(), "Users", userCredential.user.uid);
        const userDocSnap = await getDoc(userDocRef);
        const userData = userDocSnap.data();
        if (userData.paymentStatus === 100) {
          store.commit('setMessage', '退会ユーザーです。');
          return;
        }
        
        router.push('/admin');
      } catch (error) {
        switch (error.code) {
          case 'auth/user-not-found':
            store.commit('setMessage', 'ユーザーが見つかりませんでした。');
            break;
          case 'auth/wrong-password':
            store.commit('setMessage', 'パスワードが間違っています。');
            break;
          default:
            store.commit('setMessage', 'ログイン中にエラーが発生しました。もう一度お試しください。');
        }
      }
    };

    const loginWithGoogle = async () => {
      try {
        const provider = new GoogleAuthProvider();
        const userCredential = await signInWithPopup(auth, provider);
        const user = userCredential.user;
        const userDocRef = doc(getFirestore(), "Users", user.uid);
        const userDocSnap = await getDoc(userDocRef);
        const userData = userDocSnap.data();
        if (userData.paymentStatus === 100) {
          store.commit('setMessage', '退会ユーザーです。');
          return;
        }

        router.push('/admin/customize_chat');
      } catch (error) {
        store.commit('setMessage', 'ログイン中にエラーが発生しました。もう一度お試しください。');
      }
    };

    watchEffect(() => {
      const message = store.state.message;
      console.log("message changed: ", message);
      if (message) {
        setTimeout(() => {
          console.log("clearing message after 3 seconds");
          clearMessage();
        }, 3000);
      }
    });

    return { email, password, login, loginWithGoogle, clearMessage, emailRules, passwordRules };
  },
};
</script>

<style scoped>
</style>
