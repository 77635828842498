<template>
  <v-btn color="green" class="my-2 mx-2" @click="dialog = true">
      インスタグラムメディアを同期する
  </v-btn>
  <v-dialog v-model="dialog" max-width="700px">
    <v-card style="overflow-y: auto; max-height: 800px;">
        <v-col cols="12">
            <v-btn color="green" class="my-2 mx-2" @click="onClick('feed')">
                フィード、リールをキャプチャ
            </v-btn>
        </v-col>
        <v-col cols="12">
            <v-btn color="green" class="my-2 mx-2" @click="onClick('live')">
                ライブをキャプチャ
            </v-btn>
        </v-col>
        <v-col cols="12">
            <v-btn color="green" class="my-2 mx-2" @click="onClick('stories')">
                ストーリーズをキャプチャ
            </v-btn>
        </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import { collection, query, where, getDocs, doc, getFirestore, setDoc, deleteDoc } from "firebase/firestore";
export default {
  props: ['instagramId', 'pageAccessToken'],
  data() {
    return{
        captureData: null,
        existingData: null,
        userRef: null,
        dialog: false,
        mediaType:''
    }
  },
  methods: {
    async onClick(mediaType) {
        this.mediaType = mediaType;
        try {
            const response = await this.$axios.get('https://script.google.com/macros/s/AKfycbyk4PjHsXpTHXvfvoBf1P7bQVlc0cqBJ4RHZNi0gSox4SAWdOYFZZ250km_T65avYd2/exec', {
                params: {
                    pageAccessToken: this.pageAccessToken,
                    instagramId: this.instagramId,
                    mediaType: this.mediaType
                }
            });
            this.captureData = response.data.data.map(item => item.id);
            console.log(this.captureData);
        } catch(error) {
            console.error(error);
        }
        if(this.mediaType === 'feed'){
            let medias =  await this.getMedia();
            console.log(medias);
            const newIds = this.captureData.filter(id => !medias.includes(id));
            this.addMedia(newIds);
            console.log('New IDs to add:', newIds);
        }else if(this.mediaType === 'live'){
            await this.deleteLive();
            this.addLive(this.captureData);
        }else if(this.mediaType === 'stories'){
            this.addStories(this.captureData);
        }
    },
    async getMedia() {
        const uid = this.$store.state.user.uid;
        console.log(uid);
        const db = getFirestore();
        const userRef = doc(db, 'Users', uid);
        this.userRef = userRef;
        const q = query(collection(db, 'MediaIds'), where('userRef', '==', userRef));
        try {
            const querySnapshot = await getDocs(q);
            let existingData = querySnapshot.docs.map(doc => doc.id);
            console.log(existingData);
            return existingData;
        } catch (error) {
            console.log("Error getting documents: ", error);
        }
    },
    async deleteLive() {
        const uid = this.$store.state.user.uid;
        console.log(uid);
        const db = getFirestore();
        const userRef = doc(db, 'Users', uid);
        this.userRef = userRef;
        const q = query(collection(db, 'LiveIds'), where('userRef', '==', userRef));
        try {
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
            deleteDoc(doc.ref);
        });
        } catch (error) {
            console.log(error);
        }
    },
    async addMedia(newIds){
      const firestore = getFirestore();
      const connectionCollectionRef = collection(firestore, 'MediaIds');
      for (let id of newIds) {
        let docId = id.toString();
        const mediaIdDocRef = doc(connectionCollectionRef, docId);
        await setDoc(mediaIdDocRef, {
          userRef: this.userRef
        }, { merge: true });
      }
      this.dialog = false;
    },
    async addLive(newIds){
      const firestore = getFirestore();
      const connectionCollectionRef = collection(firestore, 'LiveIds');
      for (let id of newIds) {
        let docId = id.toString();
        const liveIdDocRef = doc(connectionCollectionRef, docId);
        await setDoc(liveIdDocRef, {
          userRef: this.userRef
        }, { merge: true });
      }
       this.dialog = false;
    },
    async addStories(newIds){
      const firestore = getFirestore();
      const uid = this.$store.state.user.uid;
      const userRef = doc(firestore, 'Users', uid);
      this.userRef = userRef;
      const connectionCollectionRef = collection(firestore, 'Stories');
      for (let id of newIds) {
        let docId = id.toString();
        const storiesIdDocRef = doc(connectionCollectionRef, docId);
        await setDoc(storiesIdDocRef, {
          userRef: this.userRef,
          createdAt: Date.now()
        }, { merge: true });
      }
       this.dialog = false;
    },
  },
}
</script>