<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer" :persistent="true">
      <v-list>
          <v-list-item>
              <v-img :src="require('@/assets/ainouheader.svg')" aspect-ratio="1" height="55" class="grey lighten-2"></v-img>
          </v-list-item>
          <router-link v-for="(item, index) in items" :key="index" :to="item.path" custom>
              <template v-slot="{ navigate }">
                  <v-list-item @click="updateTitleAndNavigate(item.title, navigate)">
                      <div style="display: flex; align-items: center;">
                          <v-icon class="mx-3">{{ item.icon }}</v-icon>
                          <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </div>
                  </v-list-item>
              </template>
          </router-link>
          <v-list-item @click="showLogoutDialog = true">
            <div style="display: flex; align-items: center;">
              <v-icon class="mx-3">mdi-logout</v-icon>
              <v-list-item-title>ログアウト</v-list-item-title>
             </div>
          </v-list-item>
      </v-list>

    </v-navigation-drawer>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-app-bar>
    <v-dialog v-model="showLogoutDialog" max-width="300px">
      <v-card>
        <v-card-title class="headline">ログアウトしますか？</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="signOut">はい</v-btn>
          <v-btn color="green darken-1" text @click="showLogoutDialog = false">いいえ</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { auth, signOut } from '@/firebaseInit';

export default {
  name: "AdminIndex",
  data() {
    return {
      paymentStatus: null,
      createdAt: null,
      customerId: '',
      drawer: true,
      title: "インスタ連携",
      showLogoutDialog: false,
      items: [
        { title: "インスタ連携", icon: "mdi-account", path: "/admin/create_token" },
        { title: "ユーザー設定", icon: "mdi-account", path: "/admin/user_settings"},
        { title: "支払い管理", icon: "mdi-credit-card", path: "/admin/payment_settings"},
        { title: "パッケージ管理", icon: "mdi-instagram", path: "/admin/package_management" },
        { title: "インスタグラム自動返信設定", icon: "mdi-instagram", path: "/admin/instagram_settings" },
        { title: "プレゼント企画管理", icon: "mdi-gift", path: "/admin/gift_management"},
      ],
    };
  },
  /*
  async mounted() {
    const docRef = doc(getFirestore(), "Users", this.$store.state.user.uid);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      this.paymentStatus = data.paymentStatus;
      this.createdAt = data.createdAt;
      this.customerId = data.customerId;
      console.log(this.customerId );
    } else {
      console.log("Document does not exist!");
    }
  },*/
  methods: {
    async signOut() {
      try {
        await signOut(auth);
        this.$store.commit("setUser", null);
        this.$store.commit('setMessage', 'ログアウトしました');
        this.$router.push({
          name: 'SignIn'
        });
        this.showLogoutDialog = false; // ダイアログを閉じる
      } catch (error) {
        console.error(error);
      }
    },
    updateTitleAndNavigate(newTitle, navigate) {
      this.title = newTitle;
      this.drawer = false;
      navigate();
    },
    /*
    navigate(item) {
      this.title = item.title;
      this.currentComponent = item.component;
      this.propertyValue = this.paymentStatus;
      this.creationTimeValue = this.createdAt;
      this.customerIdValue = this.customerId
      console.log( this.customerIdValue);
    }*/
  },
};
</script>
s