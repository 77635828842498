  <template>
    <v-btn color="green" class="my-2 mx-2" @click="dialog = true">作成+</v-btn>
    <v-alert
        v-if="message"
        :value="true"
        type="success"
        dismissible
    >
      {{ message }}
    </v-alert>
      <v-dialog v-model="dialog" max-width="700px" class="grey lighten-2">
        <div class="">
            <v-card style="overflow-y: auto; max-height: 500px;">
            <v-card-title>パッケージ作成</v-card-title>
            <v-card-text>
              <h3>パッケージの名前</h3>
              <v-text-field v-model="packageName" label="Package Name"></v-text-field>
            </v-card-text>
            <v-card-text>
                <v-form>
                    <template v-for="(component, index) in components" :key="index">
                    <v-card v-if="component.type === 'text'" padding="3" class="my-2">
                        <v-card-text>
                        <h3>テキスト{{index + 1}}</h3>
                        <v-textarea v-model="component.value" :label="'テキスト'"></v-textarea>
                        <v-btn @click="removeField(index)">削除</v-btn>
                        </v-card-text>
                    </v-card>
                    <v-card v-if="component.type === 'image'" padding="1" class="my-1">
                        <v-card-text>
                        <h3>画像{{index + 1}}</h3>
                        <v-file-input
                            accept=".jpg, .png, .gif, .jpeg"
                            :label="'画像'"
                            :show-size="true"
                            @change="onFileChange($event, index)"
                        ></v-file-input>
                        <v-btn @click="removeField(index)">削除</v-btn>
                        </v-card-text>
                    </v-card>
                    <v-card v-if="component.type === 'button'" padding="3" class="my-2">
                        <v-card-text>
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon color="grey lighten-1" icon v-bind="attrs" v-on="on">
                              mdi-comment-question-outline
                            </v-icon>
                        </template>
                          <span>Tooltip</span>
                        </v-tooltip>
                        <h3>ボタン{{index + 1}}</h3>
                        <v-text-field v-model="component.buttonname" :label="'ボタン名'"></v-text-field>
                        <v-select v-model="component.buttontrigger" :items="['既定のURLに遷移']" :label="'トリガーの選択'"></v-select>
                        <v-text-field v-model="component.value" :label="'遷移先URL'"></v-text-field>
                        <v-btn @click="removeField(index)">削除</v-btn>
                        </v-card-text>
                    </v-card>
                     <v-card v-if="component.type === 'template'" padding="3" class="my-2">
                        <v-card-text>
                        <h3>テンプレート{{index + 1}}</h3>
                        <v-file-input
                            accept=".jpg, .png, .gif, .jpeg"
                            :label="'画像'"
                            :show-size="true"
                            @change="onFileChange($event, index)"
                        ></v-file-input>
                        <v-text-field v-model="component.title" :label="'タイトル'"></v-text-field>
                        <v-text-field v-model="component.subtitle" :label="'サブタイトル'"></v-text-field>
                        <v-text-field v-model="component.buttonName" :label="'ボタン名'"></v-text-field>
                        <v-text-field v-model="component.buttonValue" :label="'遷移先URL'"></v-text-field>
                        <v-btn @click="removeField(index)">削除</v-btn>
                        </v-card-text>
                    </v-card> 
                    </template>
                </v-form>
            </v-card-text>
            <v-row no-gutters class="justify-start mb-3">
              <v-col cols="4" sm="4" md="4" class="my-1 mx-1" align-self-start>
                <v-btn color="info" @click="addTextField()">テキスト追加</v-btn>
              </v-col>
              <v-col cols="4" sm="4" md="4" class="my-1 mx-1" align-self-start>
                <v-btn color="info" @click="addImageField()">画像追加</v-btn>
              </v-col>
              <v-col cols="4" sm="4" md="4" class="my-1 mx-1" align-self-start>
                <v-btn color="info" @click="addButtonField()">ボタン追加</v-btn>
              </v-col>
              <v-col cols="4" sm="4" md="4" class="my-1 mx-1" align-self-start>
                <v-btn color="info" @click="addTemplateField()">テンプレート追加</v-btn>
                <v-tooltip text="テンプレートは画像、テキスト、ボタンをセットで送れる機能です">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" style="color: pink;">mdi-help</v-icon>
                </template>
              </v-tooltip>
              </v-col>
              <v-col cols="4" sm="4" md="4" class="my-1 mx-1" align-self-start>
                <v-btn @click="dialog = false" color="warning">キャンセル</v-btn>
              </v-col>
              <v-col cols="4" sm="4" md="4" class="my-1 mx-1" align-self-start>
                <v-btn color="success" @click="savePackage()">保存</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </div>
        <div class="overflow-visible">
          <PreviewPackage :components="components" @update="showMessage"/>
        </div>
        </v-dialog>
      <PackageTable ref="packageTable"/>
  </template>

  <script>
  import { getFirestore, collection, doc, setDoc } from 'firebase/firestore';
  import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
  import PackageTable from './PackageTable.vue'
  import PreviewPackage from './PreviewPackage.vue'

  export default {
    components: {
      PackageTable,
      PreviewPackage
    },
    data() {
      return {
        message: '',
        dialog: false,
        packageName: '',
        components: [
            { type: 'text', value: '' },
            { type: 'image', value: null, filename: '' },
        ]
      };
    },
    methods: {
      showMessage(message, duration) {
        this.message = message;
        setTimeout(() => {
          this.message = '';
        }, duration);
      },
      onFileChange(event, index) {
        const file = event.target.files[0];
        console.log(file.name);
        const reader = new FileReader();
        reader.onload = (e) => {
          const value = e.target.result;
          this.components[index].value = value; 
          this.components[index].filename = file.name;
        };
        reader.readAsDataURL(file);
      },
      async savePackage() {
        let hasEmptyValue = false;
        for (let i = 0; i < this.components.length; i++) {
          const component = this.components[i];
          if (component.value === '' || component.value === null) {
            hasEmptyValue = true;
            break;
          }
          if (component.type === 'text') {
            component.value = component.value.replace(/\n/g, '<br/>');
          }
        }
        if (hasEmptyValue) {
          console.log('値が入っていないコンポーネントがあります');
        } else {
          const uid = this.$store.state.user.uid;
          const title = this.packageName;
          const newComponents = await this.uploadimage(this.components);
          await this.createUserDocument(uid, title, newComponents);
          this.$refs.packageTable.fetchPackages(uid);
          this.showMessage('作成しました', 3000);
          this.dialog = false;
        }          
      },
      addTextField() {
        this.components.push({ type: 'text', value: '' });
      },
      addImageField() {
        this.components.push({ type: 'image', value: null, filename: '' });
      },
      addButtonField() {
          this.components.push({ type: 'button', value: '', buttonname: '',trigger: '' });
      },
      addTemplateField() {
          this.components.push({ type: 'template', value: null, filename: '',title: '',subtitle: '', buttonName: '', buttonValue:''});
      },
      removeField(index) {
        this.components.splice(index, 1);
      },
    async uploadimage(components) {
      const uid = this.$store.state.user.uid;
      const updatedComponents = [];
      for (let i = 0; i < components.length; i++) {
        const component = components[i];
        if (!component) {
          alert("Please select a file.");
          return;
        }
        if (component.type === 'image' && component.value || component.type === 'template' && component.value) {
          console.log(component);
          const timestamp = Date.now();
          const name = component.filename || `${timestamp}_undefined`;
          const storageRef = ref(getStorage(), `Sendimage/${uid}/${name}`);
          const uploadTask = uploadBytesResumable(storageRef, await fetch(component.value).then(res => res.blob()));
          await uploadTask;
          const downloadURL = await getDownloadURL(storageRef);
          updatedComponents.push({
            ...component,
            value: downloadURL,
          });
        } else {
          updatedComponents.push(component);
        }
      }
      return updatedComponents;
    },
      async createUserDocument(uid, title, newCompo){
        const userDocRef = doc(collection(getFirestore(), "Users"), uid);
        const packagesCollRef = collection(userDocRef, "packages");
        const packageDocRef = doc(packagesCollRef, title);
        
        for (let i = 0; i < newCompo.length; i++) {
          const component = newCompo[i];
          await setDoc(packageDocRef, {
            [i]: component,
            created_at: new Date(),
          }, { merge: true });
        }
      },
    },
  };
</script>
<style scoped>
.dialog-container {
  overflow-y: auto;
  max-height: 500px;
}
</style>